/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { strfTimeHas12Hours } from '@Helpers/DateTime'
import useSelectedEvent from '@Hooks/useSelectedEvent'
import useUser from '@Hooks/useUser'
import DataExport from '@Lib/DataExport/DataExport'
import { numberSorter, stringSorter } from '@Lib/Util'
import Card from '@Reusable/Card/Card'
import DownloadButton from '@Reusable/DownloadButton'
import { StyledTable } from '@Reusable/Styled'
import { message, Table } from 'antd'
/* eslint-disable-next-line no-restricted-imports -- TODO refactor/migrate to using date-fns-tz */
import moment from 'moment-timezone'

const exportSchema = {
  Representative: 'representative',
  Attendee: 'other',
  Email: 'otherEmail',
  Company: 'company',
  Title: 'title',
  'Operates in': 'otherCompanyCountries',
  Date: 'date',
  Status: 'status',
  Location: 'location',
  Direction: 'direction',
  Messages: 'messages'
}

interface MeetingsTableProps {
  sponsorMeetings: any,
  loading: boolean
}

const MeetingsTable = ({ sponsorMeetings, loading }: MeetingsTableProps) => {
  const { selectedEvent: event } = useSelectedEvent()
  const { user } = useUser()
  const [pageSize, setPageSize] = useState(10)

  const _onExportError = (error: { message: string }) => {
    message.error(error.message)
  }

  const _onTableChange = ({ pageSize }: any) => {
    setPageSize(pageSize)
  }

  const timeFormat = strfTimeHas12Hours(user.timeFormat || '') ? 'hh:mm A' : 'HH:mm'

  return (
    <Card
      title={'Who met who'}
      testId={'who-met-who'}
      loading={loading}
      extra={
        <DownloadButton
          tableName={'meetings-table'}
          filename={`who-met-who-${moment().format('YYYY-MM-DD')}.csv`}
          data={sponsorMeetings}
          exporter={DataExport.csv}
          exportSchema={exportSchema}
          onError={_onExportError}
        >
          {'Export (.csv)'}
        </DownloadButton>
      }
    >
      <StyledTable
        dataSource={sponsorMeetings}
        rowKey={'id'}
        scroll={{ x: 'max-content' }}
        size={'small'}
        bordered
        pagination={{
          total: sponsorMeetings.length,
          pageSize,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          showSizeChanger: sponsorMeetings.length > 10,
          pageSizeOptions: ['10', '20', '30', '60', '90', '120']
        }}
        onChange={_onTableChange}
      >
        <Table.Column
          title={'Representative'}
          dataIndex={'representative'}
          render= {(value) => <div data-test={'name'}>{value}</div>}
          sorter={(a, b) => stringSorter(a, b, 'representative')}
        />
        <Table.Column
          title={'Attendee'}
          dataIndex={'other'}
          sorter={(a, b) => stringSorter(a, b, 'other')}
        />
        <Table.Column
          title={'Email'}
          dataIndex={'otherEmail'}
          sorter={(a, b) => stringSorter(a, b, 'otherEmail')}
        />
        <Table.Column
          title={'Company'}
          dataIndex={'company'}
          sorter={(a, b) => stringSorter(a, b, 'company')}
        />
        <Table.Column
          title={'Title'}
          dataIndex={'title'}
          sorter={(a, b) => stringSorter(a, b, 'title')}
        />
        <Table.Column
          title={'Operates in'}
          dataIndex={'otherCompanyCountries'}
          sorter={(a, b) => stringSorter(a, b, 'otherCompanyCountries')}
        />
        <Table.Column
          title={'Date'}
          dataIndex={'date'}
          render={(value) => <div>{`${value === 'Custom' ? value : moment.utc(value).tz(event?.timeZone)?.format(`YYYY-MM-DD ${timeFormat}`)}`}</div>}
          sorter={(a, b) => stringSorter(a, b, 'date')}
        />
        <Table.Column
          title={'Status'}
          dataIndex={'status'}
          sorter={(a, b) => stringSorter(a, b, 'status')}
        />
        <Table.Column
          title={'Location'}
          dataIndex={'location'}
          sorter={(a, b) => stringSorter(a, b, 'location')}
        />
        <Table.Column
          title={'Direction'}
          dataIndex={'direction'}
          sorter={(a, b) => stringSorter(a, b, 'direction')}
        />
        <Table.Column
          title={'Messages'}
          dataIndex={'messages'}
          sorter={(a, b) => numberSorter(a, b, 'messages')}
        />
      </StyledTable>
    </Card>
  )
}

export default MeetingsTable

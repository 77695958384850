import { mapObjIndexed } from 'ramda'

import { FilterFieldType, FiltersType } from './Types'

const LOCAL_STORAGE_KEY = 'FilterContextState'

export const Cache = {
  setFilters: (filters: FiltersType) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(filters))
  },
  clear: () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY)
  },
  getFilters: () => {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}')
  }
}

export function getDefaultValues<T extends string>(filterFields: FilterFieldType<T>[]) {
  const values: Record<string, unknown> = {}

  filterFields.forEach((field) => {
    if (field.type === 'group') {
      field.fields.forEach((subField) => {
        values[subField.key] = subField?.defaultValue !== undefined ? subField.defaultValue : null
      })
    } else {
      values[field.key] = field?.defaultValue !== undefined ? field.defaultValue : null
    }
  })

  return values
}

// React-hook-form doesn't support undefined as a field value
// But we should be able to store undefined in the filter state
export function fieldValueToCachedValue(value: string) {
  if ([null, ''].includes(value)) return undefined
  if (Array.isArray(value) && value.length === 0) return undefined

  return value
}

export function transformToCachedValues(values: Record<string, unknown>, filterFields: FilterFieldType[]) {
  return mapObjIndexed((value, key) => {
    const field = filterFields.find((field) => field.key === key)

    if (!field || field.type === 'group') {
      return undefined
    }

    const converter = field?.fieldValueToCachedValue || fieldValueToCachedValue

    return field?.isDisabled?.(values) ? undefined : converter(value as string)
  }, values)
}

export function transformCachedValueToFieldValue(value: unknown, filterField: FilterFieldType) {
  if ('cachedValueToFieldValue' in filterField && filterField.cachedValueToFieldValue) {
    return filterField.cachedValueToFieldValue(value as string)
  }

  return value
}

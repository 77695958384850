import React from 'react'
import CloseOutlined from '@ant-design/icons/CloseOutlined'
import styled from '@emotion/styled'
import { hexToRGB } from '@Lib/Util'
import { colors, margins, radius } from '@Theme'

interface TagProps {
  color?: string,
  children: React.ReactNode,
  closable?: boolean,
  boxed?: boolean
}

export const Tag = ({ color, children, closable = false, boxed = false, ...rest }: TagProps) => {
  return (
    <Container
      color={color || colors.muted}
      boxed={boxed}
      {...rest}
    >
      <span>
        {children}
        {closable && (
          <CloseOutlined
            style={{ fontSize: 10, marginLeft: 4 }}
          />
        )}
      </span>
    </Container>
  )
}

const Container = styled.div<{ boxed?: boolean, color: string }>(({ color, boxed }) => `
  background-color: ${hexToRGB(color, 0.05)};
  color: ${color};
  display: inline-block;
  line-height: 20px;
  padding: 0 ${margins.halfBaseMargin}px;
  border-radius: ${radius.medium};
  white-space: nowrap;
  margin-right: ${margins.halfBaseMargin}px;

  ${boxed && `
    height: auto;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: 1px solid ${hexToRGB(color, 0.5)};
  `}

  &:hover: {
    background-color: ${hexToRGB(color, 0.2)}
  }
`)

export default Tag

import { ReactNode } from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { StyledFlex, StyledHeaderText, StyledLightInnerTooltip } from '@Reusable/Styled'
import theme, { colors } from '@Theme'
import { Spin, Tooltip } from 'antd'

interface TableTitleProps {
  title: string,
  TooltipNode?: ReactNode,
  margin?: string,
  testId: string,
  loading?: boolean,
  count?: number
}

const TableTitle = ({ title, count, TooltipNode, margin, testId, loading }: TableTitleProps) => {
  return (
    <ReStyledFlex margin={margin} data-test={`${testId}-title`}>
      <StyledHeaderText margin={'0 10px 0'}>{title}</StyledHeaderText>
      {typeof count === 'number' && <CountText>{`(${count})`}</CountText>}
      {TooltipNode &&
      <Tooltip
        placement={'topLeft'}
        title={<StyledLightInnerTooltip>{TooltipNode}</StyledLightInnerTooltip>}
        color={theme.colors.primaryBackground}
      >
        <InfoCircleOutlined style={{ cursor: 'pointer' }}/>
      </Tooltip>
      }
      {loading && <Spin className={'spinner'}/>}
    </ReStyledFlex>
  )
}

export default TableTitle

const ReStyledFlex = styled(StyledFlex)({
  lineHeight: '28px',
  '>span': {
    marginTop: 2,
    '>svg': {
      height: 16,
      width: 16
    }
  },
  '.spinner': {
    marginTop: 4,
    paddingLeft: 12,
    '>span': {
      display: 'flex'
    }
  }
})

const CountText = styled.span`
  font-weight: 400;
  color: ${colors.muted};
  margin-right: 8px;
`

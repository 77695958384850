/* eslint-disable @typescript-eslint/no-explicit-any */
export interface EventParamsTypes {
  [key: string]: any
}

export type ProfileType = {
  id: number,
  userId: boolean,
  firstName: string,
  coverPhotoUrl: string,
  lastName: string,
  imageUrl: string,
  companyName: string,
  companyTitle: string,
  matchingAttendeeInterests: unknown[]
}

export enum SponsorTabKeyType {
  DETAILS = 'details',
  REPS = 'representatives',
  PRODUCTS = 'products',
  DOWNLOADS = 'downloads'
}

export type SponsorTabType = {
  key: SponsorTabKeyType,
  id: string,
  label: string,
  title: string,
  value: string,
  name: string,
  position: number,
  limit: number,
  disabled: boolean
}

export type AttendeeType = {
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  companyName: string,
  companyTitle: string,
  type: string,
  persona?: { name: string },
  industry?: { name: string },
  function?: { name: string },
  selectedInterests?: SelectedInterest[]
}

export type Interest = {
  id: string,
  eventId: string,
  name: string,
  position: number
}

export type Intent = {
  id: string,
  matchLabel: string,
  pair: Intent,
  position: number,
  profileLabel: string,
  selectionLabel: string,
  statisticsLabel: string
}

export type SelectedInterest = {
  id: string,
  intent: Intent,
  interest: Interest,
  selector: AttendeeType
}

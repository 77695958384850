import { Controller, ControllerProps, RegisterOptions, useFormContext } from 'react-hook-form'
import { Form } from 'antd'
import { FormItemProps } from 'antd/lib/form'
import { pathOr } from 'ramda'

export const formItemLayout = { labelCol: { span: 24 } } as const


interface FieldProps extends Omit<FormItemProps, 'rules'> {
  label?: string,
  name: string,
  required?: boolean,
  rules?: RegisterOptions,
  render: ControllerProps['render'] | null,
  alignLabelAndField?: boolean
}

/**
 * This is a wrapper component for Ant Design's `Form.Item` and react-hook-form's `Controller`.
 * It allows you to easily move away from AntD's Form and use react-hook-form instead as you
 * can pass any (Ant Design) component to the `render` prop.
 *
 * @example
 * <Field
 *  name={'name'}
 *  label={'Name'}
 *  rules={{ required: 'Name is required' }}
 *  render={({ field }) => (
 *   <Input {...field} />
 *  )}
 * />
 */
export function Field({ name, label, rules, render, alignLabelAndField, ...rest }: FieldProps) {
  const { control, formState: { errors } } = useFormContext()
  const error = pathOr(errors[name], name.split('.'), errors)

  if (!render) return null

  return (
    <Form.Item
      data-test={`field-${name}`}
      label={label}
      help={error?.message}
      validateStatus={error ? 'error' : undefined}
      {...(alignLabelAndField ? {} : formItemLayout)}
      {...rest}
    >
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={render}
      />
    </Form.Item>
  )
}

import React from 'react'
import { useFormContext } from 'react-hook-form'
import styled from '@emotion/styled'
import { margins } from '@Theme'
import { Checkbox, Select } from 'antd'

import { FilterFieldType } from './Types'

interface FilterDrawerFieldProps<T extends string> {
  value: unknown,
  onChange: (value: unknown) => void,
  filter: FilterFieldType<T>,
  mode?: 'multiple' | 'tags'
}

function FilterDrawerField<T extends string>({ value, onChange, filter, ...rest }: FilterDrawerFieldProps<T>) {
  if (filter.type === 'group') return null

  const form = useFormContext()
  const disabled = !!filter.isDisabled?.(form.getValues())
  const inputProps = { ...rest, ...(filter.inputProps || {}), disabled }

  if (filter.type === 'checkbox') {
    const CheckboxGroup = 'layout' in filter && filter?.layout === 'vertical' ? VerticalCheckboxGroup : Checkbox.Group

    return (
      <CheckboxGroup
        data-test={`filter-${filter.key}`}
        value={!Array.isArray(value) ? [value] : value}
        style={{ width: '100%' }}
        options={filter.options}
        onChange={onChange}
        {...inputProps}
      />
    )
  }

  if (filter.type === 'select') {
    const mode = inputProps?.mode ?? 'multiple'
    const _value = value && !Array.isArray(value) && mode === 'multiple' ? [value] : value

    return (
      <Select
        data-test={`filter-${filter.key}`}
        value={_value}
        mode={mode}
        options={filter.options}
        onChange={(updatedValue) => {
          // react-form-hook doesn't like to have undefined as a value
          onChange(updatedValue === undefined ? null : updatedValue)
        }}
        {...inputProps}
      />
    )
  }

  if (filter.type === 'custom' && filter.render) {
    return filter.render({ value, onChange, filter, ...inputProps })
  }

  return null
}

const VerticalCheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  gap: ${margins.halfBaseMargin}px;
`

export default FilterDrawerField

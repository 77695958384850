/* eslint-disable @typescript-eslint/no-explicit-any */
import { useReducer } from 'react'
import styled from '@emotion/styled'
import { useMediaContext } from '@MediaContext'
import ImageUpload from '@Reusable/ImageUpload/ImageUpload'
import InfoSubTitle from '@SponsorCommon/InfoSubTitle'
import { colors } from '@Theme'
import { Form, Input, Select } from 'antd'

import { ModalTypes } from './MediaModal'

interface ModalFormProps {
  type: ModalTypes,
  initialValues?: any
}

const formItemLayout = {
  labelCol: {
    span: 24
  },
  wrapperCol: {
    span: 24
  }
}

export enum bodyType {
  IFRAME = 'iframe',
  IMAGE = 'image',
  EMPTY = ''
}

export type FormProps = {
  id?: number | string,
  bodyType?: bodyType,
  title?: string,
  url?: string,
  image?: string,
  fieldsTouched?: boolean
}

enum actionTypes {
  SET_INITIAL_VALUES = 'setInitialValues',
  SET_SLIDE_TYPE = 'setSlideType',
  SET_TITLE = 'setTitle',
  SET_URL = 'setUrl',
  SET_IMAGE = 'setImage',
  SET_FIELDS_TOUCHED = 'setFieldsTouched'
}

type Action =
  | { type: actionTypes.SET_SLIDE_TYPE, payload: bodyType }
  | { type:
  actionTypes.SET_TITLE
  | actionTypes.SET_URL
  | actionTypes.SET_IMAGE, payload: string }
  | { type: actionTypes.SET_INITIAL_VALUES, payload: FormProps }
  | { type: actionTypes.SET_FIELDS_TOUCHED, payload: boolean }

const reducer = (state: FormProps, action: Action): FormProps => {
  switch (action.type) {
    case actionTypes.SET_INITIAL_VALUES:
      return {
        ...action.payload
      }
    case actionTypes.SET_SLIDE_TYPE:
      return {
        ...state,
        bodyType: action.payload
      }
    case actionTypes.SET_TITLE:
      return {
        ...state,
        title: action.payload
      }
    case actionTypes.SET_URL:
      return {
        ...state,
        url: action.payload
      }
    case actionTypes.SET_IMAGE:
      return {
        ...state,
        image: action.payload
      }
    case actionTypes.SET_FIELDS_TOUCHED:
      return {
        ...state,
        fieldsTouched: true
      }
    default:
      return { ...state }
  }
}

const ModalForm = ({ type }: ModalFormProps) => {
  const { mediaState, setMediaState } = useMediaContext()
  const mediaDeckItem = mediaState?.mediaDeckItem
  const [state, dispatch] = useReducer(reducer, mediaDeckItem)

  const _shouldRenderImageField = () => {
    return state?.bodyType === bodyType.IMAGE
  }

  const _shouldRenderUrlField = () => {
    return type === ModalTypes.SIDE_BAR_ITEM || state?.bodyType === bodyType.IFRAME
  }

  const _handleTypeChange = (type: bodyType) => {
    dispatch({
      type: actionTypes.SET_SLIDE_TYPE,
      payload: type
    })
    dispatch({
      type: actionTypes.SET_URL,
      payload: ''
    })
  }

  const _handleTitleChange = (event: { target: { value: string } }) => {
    const { value } = event.target
    dispatch({
      type: actionTypes.SET_TITLE,
      payload: value
    })
  }

  const _handleUrlChange = (event: { target: { value: string } }) => {
    const { value } = event.target
    dispatch({
      type: actionTypes.SET_URL,
      payload: value
    })
  }

  const _handleImageUrlChange = (value: string) => {
    dispatch({
      type: actionTypes.SET_URL,
      payload: value
    })
  }

  const _titleLength = mediaState?.mediaDeckItem?.title?.length ?? 0

  return (
    <DraggableItemFormContainer data-test={'modal-form'}>
      <Form
        style={{ maxWidth: 600 }}
        onFieldsChange={ () => setMediaState({ mediaDeckItem: { ...state }, fieldsTouched: true })}
        initialValues={{
          bodyType: mediaDeckItem?.bodyType,
          title: mediaDeckItem?.title,
          url: mediaDeckItem?.url
        }}
        layout={'vertical'}
      >
        {type === ModalTypes.CAROUSEL_SLIDE &&
        <Form.Item
          label={'Slide type'}
          hasFeedback
          name={'bodyType'}
          rules={[{ required: true }]}
          data-test={'slide-type-select'}
          {...formItemLayout}
        >
          <Select onChange={_handleTypeChange} placeholder={'Select type of slide'}>
            <Select.Option value={bodyType.IMAGE}>{'Image (upload)'}</Select.Option>
            <Select.Option value={bodyType.IFRAME}>{'Embed web page / video / stream (URL)'}</Select.Option>
          </Select>
        </Form.Item>
        }

        <Form.Item
          label={`Title (${_titleLength} / 50)`}
          hasFeedback
          name={'title'}
          rules={[
            {
              required: true,
              message: 'Title is required!'
            },
            {
              max: 50,
              message: 'Title is too long!'
            }
          ]}
          data-test={'title-input'}
          {...formItemLayout}
        >
          <Input
            type={'text'}
            placeholder={'Add title'}
            onChange={_handleTitleChange}
          />
        </Form.Item>
        {_shouldRenderUrlField() && (
          <>
            <Form.Item
              label={'Url'}
              hasFeedback
              name={'url'}
              rules={[
                {
                  required: true,
                  message: 'Url is required!'
                }
              ]}
              data-test={'url-input'}
              {...formItemLayout}

            >
              <StyledInput
                type={'text'}
                placeholder={'Embedded url'}
                onChange={_handleUrlChange}

              />
            </Form.Item>
            <InfoSubTitle testId={'embedded-url-subtitle'}>
              {'All material has to be in an embeddable form. '}
              <a href={'https://help-sponsors.brella.io/en/articles/177880-retrieving-embed-urls'} target={'_blank'} rel={'noreferrer'}>{'Learn more'}</a>
            </InfoSubTitle>
          </>

        )}
        {_shouldRenderImageField() && (
          <Form.Item
            className={'image-upload-item'}
            label={'Image'} name={'image'}
            rules={[
              {
                required: true,
                message: 'Image is required!'
              }
            ]}
            {...formItemLayout}
          >
            <ImageUpload
              cropperEnabled
              width={'100%'}
              helperText = {[
                { id: 1, text: 'Recommended ratio 16:9' }
              ]}
              onChange={_handleImageUrlChange}
              preview={state?.url}
            />

          </Form.Item>
        )}
      </Form>
    </DraggableItemFormContainer>
  )
}

export default ModalForm

const DraggableItemFormContainer = styled('div')({
  '.image-upload-item': {
    display: 'block',
    '.ant-form-item-control': {
      maxWidth: '100%'
    }
  }
})
const StyledInput = styled(Input)({
  backgroundColor: `${colors.lightGrayBackground} !important`
})

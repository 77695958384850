import { ReactNode } from 'react'
import styled from '@emotion/styled'
import TableTitle from '@SponsorCommon/TableTitle'
import { Card as AntdCard } from 'antd'
import CSS from 'csstype'

interface CardProps {
  children: ReactNode,
  TooltipNode?: ReactNode,
  title?: string,
  testId: string,
  testTotal?: string,
  extra?: ReactNode,
  paddingTop?: CSS.Property.PaddingTop,
  paddingBottom?: CSS.Property.PaddingBottom,
  padding?: CSS.Property.Padding,
  margin?: CSS.Property.Margin,
  loading?: boolean,
  empty?: boolean,
  style?: CSS.Properties,
  count?: number
}

// TODO refactor this component!
const Card = ({ children, TooltipNode, title, count, testId, testTotal, extra, paddingTop, paddingBottom, margin, loading, empty, style, padding }: CardProps) => {
  return (
    <StyledAntdCard
      data-test={`${testId}-card`}
      data-test-total={testTotal}
      style={{ boxShadow: 'none', border: 'none', borderRadius: 8, height: '100%', margin, ...style }}
      extra={extra}
      title= {title && <TableTitle
        title={title} testId={testId} TooltipNode={TooltipNode}
        loading={loading}
        count={count}
      /> }
      padding={padding}
      paddingtop={paddingTop} paddingbottom={paddingBottom} empty={empty ? 'true' : null}
    >
      {children}
    </StyledAntdCard>
  )
}

export default Card

type StyledAntdCardProps = {
  paddingtop?: string | number,
  paddingbottom?: string | number,
  padding?: CSS.Property.Padding,
  empty?: string | null
}

const StyledAntdCard = styled(AntdCard)<StyledAntdCardProps>({
}, (props) => ({
  '.ant-card-body': {
    paddingTop: props.paddingtop,
    paddingBottom: props.paddingbottom,
    padding: props.padding,
    display: props.empty ? 'flex' : 'block',
    height: '80%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '.ant-card-head': {
    border: 'none',
    paddingTop: 6
  },
  '.ant-card-head-title': {
    paddingBottom: 8
  }
}))

import { useParams } from 'react-router-dom'
import { visitorsExportQuery } from '@EntitySauce/Queries'
import { strfTimeHas12Hours } from '@Helpers/DateTime'
import useWindowDimensions from '@Helpers/Hooks/getWindowDimensions'
import useUser from '@Hooks/useUser'
import Card from '@Reusable/Card/Card'
import ExportCsvButtonGet from '@Reusable/ExportCsvButton/ExportCsvButtonGet'
import MediaAnalyticsTable from '@Reusable/MediaAnalyticsTable'
import MediaTimeTable from '@Reusable/MediaTimeTable'
import MediaUserTable from '@Reusable/MediaUserTable'
import { StyledFlex } from '@Reusable/Styled'
import { Pagination } from 'antd'
import { EventParamsTypes } from 'src/Types'

export type UserColWidthType = {
  avatar: number,
  name: number,
  jobTitle: number,
  company: number,
  timeSpentInBooth: number,
  contentViewTime: number,
  contentViewCount: string
}

export type AnalyticsColWidthType = {
  contentType: number,
  contentTitle: number,
  url: number,
  contentViewTime: number,
  contentViewCount: string
}

export type TimeColWidthType = {
  startTime: number,
  endTime: number,
  contentViewDuration: string
}

export type TrackingSessionSlicesType = unknown[] | null | undefined
interface AnalyticsProps {
  visitorsInfo: {
    trackingSessions: TrackingSessionSlicesType[] | null | undefined
  }[] | undefined,
  loading: boolean,
  setPageNumber: (pageNumber: number) => void,
  setPageSize: (pageSize: number) => void,
  pageProps: {
    pageSize: number,
    pageNumber: number
  },
  visitorsMeta: {totalCount: number}
}

const Analytics = ({
  visitorsInfo,
  loading,
  setPageNumber,
  setPageSize,
  pageProps,
  visitorsMeta
}: AnalyticsProps) => {
  const _onTableChange = () => {
    window.scrollTo(0, 0)
  }

  const windowDimensions = useWindowDimensions()
  const { user } = useUser()
  const { width: screenWidth } = windowDimensions
  const baseCellWidth = screenWidth > 748 ? (screenWidth - 48) / 7 : 100

  const USER_TABLE_COLUMN_WIDTH: UserColWidthType =
   {
     avatar: 48,
     name: baseCellWidth,
     jobTitle: baseCellWidth,
     company: baseCellWidth,
     timeSpentInBooth: baseCellWidth,
     contentViewTime: baseCellWidth,
     contentViewCount: 'auto'
   }
  const ANALYTICS_TABLE_COLUMN_WIDTH: AnalyticsColWidthType = {
    contentType: USER_TABLE_COLUMN_WIDTH.name,
    contentTitle: USER_TABLE_COLUMN_WIDTH.jobTitle,
    url: USER_TABLE_COLUMN_WIDTH.company + USER_TABLE_COLUMN_WIDTH.timeSpentInBooth,
    contentViewTime: USER_TABLE_COLUMN_WIDTH.contentViewTime,
    contentViewCount: USER_TABLE_COLUMN_WIDTH.contentViewCount
  }
  const TIME_TABLE_COLUMN_WIDTH: TimeColWidthType = {
    startTime: ANALYTICS_TABLE_COLUMN_WIDTH.contentType + ANALYTICS_TABLE_COLUMN_WIDTH.contentTitle,
    endTime: ANALYTICS_TABLE_COLUMN_WIDTH.url,
    contentViewDuration: ANALYTICS_TABLE_COLUMN_WIDTH.contentViewCount
  }
  const _expandedRowRender = (record: { trackingSessions: {
    trackingSessionSlices: TrackingSessionSlicesType | null | undefined
  }[] | null | undefined }) => {
    return (
      <MediaAnalyticsTable
        data={record?.trackingSessions ?? []}
        expandedRowRender={_renderTimeSlices}
        colWidth={ANALYTICS_TABLE_COLUMN_WIDTH}
      />
    )
  }

  const _renderTimeSlices = (record: { trackingSessionSlices: TrackingSessionSlicesType[] | null | undefined }) => {
    return (
      <MediaTimeTable
        data={record?.trackingSessionSlices ?? []}
        timeFormat={strfTimeHas12Hours(user.timeFormat || '') ? 'hh:mm:ss A' : 'HH:mm:ss'}
        colWidth={TIME_TABLE_COLUMN_WIDTH}
      />
    )
  }

  const _TooltipNode = (
    <span>
      {'See how participants interact with your virtual booth. '}
      <a href={'https://help.brella.io/en/sponsors/virtual-booth-traffic-analytics-for-sponsors'} target={'_blank'} rel={'noreferrer'}>{'Learn more'}</a>
    </span>
  )

  const params = useParams<EventParamsTypes>()
  const { eventId, sponsorId } = params

  if (!visitorsInfo) return null
  return (
    <Card
      testId={'visitors'}
      title={'Visitors'}
      count={visitorsMeta?.totalCount}
      TooltipNode={_TooltipNode}
      loading={loading}
      extra={
        <ExportCsvButtonGet
          query={visitorsExportQuery(eventId, sponsorId)}
        />
      }
    >
      <MediaUserTable
        data={visitorsInfo}
        onTableChange={_onTableChange}
        expandedRowRender={_expandedRowRender}
        colWidth={USER_TABLE_COLUMN_WIDTH}
      />
      <StyledFlex justifyContent={'flex-end'} margin={'24px 0 0'}>
        {visitorsMeta?.totalCount > 0 &&
          <Pagination
            className={'pagination'}
            data-test={'pagination'}
            total={visitorsMeta?.totalCount}
            defaultPageSize={pageProps.pageSize}
            onChange={setPageNumber}
            current={pageProps.pageNumber}
            showSizeChanger
            onShowSizeChange={(__, pageSize) => {
              setPageNumber(1)
              setPageSize(pageSize)
            }}
          />
        }
      </StyledFlex>
    </Card>
  )
}

export default Analytics

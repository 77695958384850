import styled from '@emotion/styled'
import Tag from '@Reusable/Tag'
import { colors, margins } from '@Theme'
import { Tooltip } from 'antd'
import { SelectedInterest } from 'src/Types'

import { groupInterestsByIntent } from './helpers'

interface SelectedIntentTagsProps {
  selectedInterests?: SelectedInterest[]
}

/**
 * This is a list of offer type of intent ids because
 * the API doesn't return the information about the type of intent.
 * The intent type is used to highlight and sort the tags base on the type.
 *
 * 3 = Sell/Offer
 * 5 = Hire someone
 * 7 = Investing in
 * 9 = Mentoring in
 */
const OFFER_INTENT_IDS = ['3', '5', '7', '9']

function SelectedIntentTags({ selectedInterests = [] }: SelectedIntentTagsProps) {
  const intentGroups = groupInterestsByIntent(selectedInterests)

  // Move offer intents to bottom
  const sortedIntentGroups = intentGroups.sort((a, b) => {
    if (!OFFER_INTENT_IDS.includes(a.intent.id) && OFFER_INTENT_IDS.includes(b.intent.id)) {
      return -1
    }

    return 0
  })

  return (
    <>
      {sortedIntentGroups.map(({ intent, interests }) => (
        <Tooltip
          key={intent.statisticsLabel}
          title={(
            <>
              <InterestListTitle>{intent.profileLabel}</InterestListTitle>
              {interests.map(({ id, name }) => <div key={id}>{name}</div>)}
            </>
          )}
        >
          <Tag
            boxed
            color={colors[OFFER_INTENT_IDS.includes(intent.id) ? 'secondary' : 'primary']}
          >
            {`${intent.statisticsLabel} (${interests.length})`}
          </Tag>
        </Tooltip>
      ))}
    </>
  )
}

const InterestListTitle = styled.div`
  font-weight: 500;
`

export const TagContainer = styled.div<{ vertical?: boolean }>(({ vertical }) => `
  display: flex;
  flex-wrap: wrap;
  ${vertical ? 'flex-direction: column;' : ''}
  gap: ${margins.halfBaseMargin}px;
  align-items: flex-start;

  .tag {
    white-space: nowrap;
  }
`)

export default SelectedIntentTags

import { ReactNode } from 'react'
import styled from '@emotion/styled'
import theme from '@Theme'
import { ProfileType } from 'src/Types'

import UserAvatar from '../Avatar'

const HOVER_SHADOW = '0 1px 0px 0 rgba(0,0,0,0.02), 0 5px 10px 0 rgba(0,0,0,0.05)'

interface ProfileCardProps {
  children: ReactNode,
  dataTest: string,
  profile: ProfileType
}

const ProfileCardPreview = ({ children, dataTest, profile }: ProfileCardProps) => {
  return (
    <ProfileCardContainer
      data-test={dataTest || `profile-card-${profile?.id}`}
    >
      <Content>
        <AttendeeProfile>
          <Avatar {...profile} />
          <AttendeeInfo>
            <Name data-test={'attendee-card-name'}>
              {`${profile?.firstName || ''} ${profile?.lastName || ''}`}
            </Name>
            <CompanyTitle
              companyName={profile.companyName}
              companyTitle={profile.companyTitle}
            />
          </AttendeeInfo>
        </AttendeeProfile>
        {children}
      </Content>
    </ProfileCardContainer>
  )
}

const Avatar = ({ userId, firstName, lastName, imageUrl }: ProfileType) => {
  return (
    <UserAvatar.Mobile
      url={imageUrl}
      firstName={firstName}
      lastName={lastName}
      userId={userId}
      withBorder
      rounded
    />
  )
}

const CompanyTitle = ({ companyName, companyTitle }: { companyName: string, companyTitle:string }) => {
  const titleParts = [companyName, companyTitle].filter(Boolean)
  return <Title>{titleParts.join(', ')}</Title>
}

const ProfileCardContainer = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  position: 'relative',
  textAlign: 'left',
  borderRadius: theme.radius.large,
  border: `1px solid ${theme.colors.lightBorder}`
}, ({ onClick }) => ({
  ...(onClick
    ? {
      cursor: 'pointer',
      ':hover': {
        boxShadow: HOVER_SHADOW
      }
    }
    : {})
}))

const Content = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing.vertical.xl,
  paddingTop: '0'
})

const AttendeeProfile = styled('div')({
  zIndex: 1,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  width: '100%',
  marginTop: theme.margins.baseMargin
})

const AttendeeInfo = styled('div')({
  flex: 1,
  overflow: 'hidden',
  marginLeft: theme.margins.halfBaseMargin
})

const Name = styled('h2')({
  ...theme.text.h2,
  fontSize: 16,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginBottom: 4
})

const Title = styled('p')({
  ...theme.text.body,
  minHeight: 18,
  fontSize: 12,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginBottom: 0,
  color: theme.colors.secondaryText
})

export default ProfileCardPreview

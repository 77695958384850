import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { CloudDownloadOutlined, FilterOutlined } from '@ant-design/icons'
import styled, { StyledComponent } from '@emotion/styled'
import { headerDefinitions } from '@EntitySauce/Api/ApiHeaders'
import { leadQuery } from '@EntitySauce/Queries'
import useSelectedEvent from '@Hooks/useSelectedEvent'
import useSponsorStatistics from '@Hooks/useSponsorStatistics/useSponsorStatistics'
import { capitalizeFirstLetter } from '@Lib/Util'
import Card from '@Reusable/Card/Card'
import { useFilters, withFilterContext } from '@Reusable/Filter/FilterContext'
import FilterDrawer from '@Reusable/Filter/FilterDrawer'
import SelectedIntentTags, { TagContainer } from '@Reusable/SelectedIntentTags/SelectedIntentTags'
import Tag from '@Reusable/Tag'
import { entitySauce } from '@State'
import theme, { colors, margins } from '@Theme'
import { Badge, Button, message, Popconfirm, Radio, Table, TableProps, Tooltip } from 'antd'
import { FilterValue } from 'antd/lib/table/interface'
import { isAfter, isBefore } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import { AttendeeType, EventParamsTypes } from 'src/Types'

import { LeadData, LeadEditData } from './interfaces'
import LeadEditForm from './LeadEditForm'

import type { ColumnsType, TablePaginationConfig } from 'antd/es/table'

type LeadColumnsType = ColumnsType<LeadData>
type ScanningDirection = 'outbound' | 'inbound'

const DEFAULT_PAGINATION_CONFIG = { page: { number: 1, size: 10 } }

function Leads(): JSX.Element {
  const [showEditModal, setShowEditModal] = useState(false)
  const [editLeadId, setEditLeadId] = useState<string | null>(null)
  const [editLeadName, setEditLeadName] = useState('')
  const [editLeadNotes, setEditLeadNotes] = useState('')
  const [scanningDirection, setScanningDirection] = useState<ScanningDirection>('outbound')
  const [paginationConfig, setPaginationConfig] = useState(DEFAULT_PAGINATION_CONFIG)
  const { selectedEvent: event } = useSelectedEvent()
  const params = useParams<EventParamsTypes>()

  const filterContext = useFilters('leads')
  const { attendeeStats, isFetching: isLoadingRepresentatives } = useSponsorStatistics()
  const representativeOptions = attendeeStats.map((rep: AttendeeType) => ({
    text: `${rep.firstName} ${rep.lastName}`,
    label: `${rep.firstName} ${rep.lastName}`,
    value: rep.id
  }))
  const representativeFilterKey = scanningDirection === 'inbound' ? 'attendee_id' : 'created_by_id'

  const { data: { data: leads, meta: leadsMeta }, isFetching: isFetchingLeads, refetch: refetchLeads } = entitySauce.useGet(
    leadQuery(params.eventId, params.sponsorId, {
      page: {
        size: paginationConfig.page.size,
        number: paginationConfig.page.number
      },
      scanned_by: scanningDirection === 'inbound' ? 'others' : 'self',
      [representativeFilterKey]: filterContext.filters.selectedRepresentative
    }),
    { refetchOnWindowFocus: false }
  )

  const leadsCount = leadsMeta?.totalCount || 0

  const [deleteLead] = entitySauce.useRemove(
    leadQuery(params.eventId, params.sponsorId),
    {
      onSuccess: () => {
        message.success('Lead deleted successfully.')
        refetchLeads()
      },
      onError: () => {
        message.error('Couldn\'t delete the lead. Please try again.')
      }
    }
  )

  const [patchLead] = entitySauce.usePatch(leadQuery(params.eventId, params.sponsorId), {
    onSuccess: () => {
      message.success('Changes saved successfully.')
      refetchLeads()
    },
    onError: () => {
      message.error('Couldn\'t save changes. Please try again.')
    },
    onSettled: () => {
      onEditCancel()
    }
  })

  const onEditCancel = () => {
    setShowEditModal(false)
    setEditLeadId(null)
    setEditLeadName('')
    setEditLeadNotes('')
  }

  const onEditSubmit = (data: LeadEditData) => {
    patchLead({
      id: editLeadId,
      data: data
    })
  }

  const onDelete = (id: string) => {
    deleteLead({
      id: id
    })
  }

  const onTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>) => {
    filterContext.setFilters({
      selectedRepresentative: filters['who-scanned']?.[0] || filters['scanned-rep']?.[0]
    })

    setPaginationConfig((prevState) => ({
      ...prevState,
      page: {
        size: pagination.pageSize ?? prevState.page.size,
        number: pagination.current ?? prevState.page.number
      }
    }))
  }

  const isLoading = isFetchingLeads

  const columnsBase: LeadColumnsType = [
    {
      title: 'Scanned registrant',
      key: 'prospect-name',
      render: (value: LeadData) => {
        const attendee = getProspectAttendee(value, scanningDirection)

        if (attendee) {
          return `${attendee.firstName} ${attendee.lastName}`
        }

        return <Tag boxed>{'Deleted user'}</Tag>
      }
    },
    {
      title: 'Scanned email',
      key: 'prospect-email',
      render: (value: LeadData) => {
        const attendee = getProspectAttendee(value, scanningDirection)

        if (attendee) {
          return attendee.email
        }

        return <Tag boxed>{'Deleted user'}</Tag>
      }
    },
    {
      title: 'Scanned company',
      key: 'prospect-company',
      render: (value: LeadData) => {
        const attendee = getProspectAttendee(value, scanningDirection)

        if (attendee) {
          return attendee.companyName
        }

        return <Tag boxed>{'Deleted user'}</Tag>
      }
    },
    {
      title: 'Scanned title',
      key: 'prospect-title',
      render: (value: LeadData) => {
        const attendee = getProspectAttendee(value, scanningDirection)

        if (attendee) {
          return attendee.companyTitle
        }

        return <Tag boxed>{'Deleted user'}</Tag>
      }
    },
    {
      title: 'Intents & Interests',
      key: 'interests',
      width: 325,
      render: (value: LeadData) => {
        const attendee = getProspectAttendee(value, scanningDirection) as AttendeeType

        return (
          <TagContainer>
            <SelectedIntentTags selectedInterests={attendee?.selectedInterests} />
          </TagContainer>
        )
      }
    },
    {
      title: 'Identity',
      key: 'identity',
      render: (value: LeadData) => {
        if (!value.attendee) {
          return null
        }

        const attendee = getProspectAttendee(value, scanningDirection) as AttendeeType

        const identities = [
          attendee?.persona,
          attendee?.industry,
          attendee?.function
        ].filter(Boolean)

        return (
          <TagContainer vertical>
            {identities.map((identity) => (
              <Tag key={identity?.name} boxed>{identity?.name}</Tag>
            ))}
          </TagContainer>
        )
      }
    },
    {
      title: 'Scanning time',
      dataIndex: 'createdAt',
      render: (value: string) => {
        const date = utcToZonedTime(value, event.timeZone)
        const isBeforeEvent = isBefore(date, utcToZonedTime(event.startTimeUtc, event.timeZone))
        const isAfterEvent = isAfter(date, utcToZonedTime(event.endTimeUtc, event.timeZone))
        const status = isBeforeEvent ? 'before' : isAfterEvent ? 'after' : 'during'
        const statusColors = {
          before: colors.secondary,
          during: colors.primary,
          after: undefined
        }

        return (
          <>
            <div style={{ marginBottom: 2 }}>{format(date, 'dd.MM.yyyy HH:mm O')}</div>
            <Tag boxed color={statusColors[status]}>{`${capitalizeFirstLetter(status)} event`}</Tag>
          </>
        )
      }
    }
  ]

  const columnsOutbound: LeadColumnsType = [
    {
      title: 'Who scanned',
      key: 'who-scanned',
      filteredValue: [filterContext.filters?.selectedRepresentative as string].filter(Boolean),
      filterMultiple: false,
      filters: representativeOptions,
      render: (value: LeadData) => {
        if (value.createdBy) {
          return `${value.createdBy.firstName} ${value.createdBy.lastName}`
        }

        return <Tag boxed>{'Deleted user'}</Tag>
      }
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      className: 'notes',
      width: '300px'
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (value: LeadData) => (
        <>
          <Popconfirm
            title={'Are you sure you want to delete this scan?'}
            onConfirm={() => onDelete(value.id)}
            okText={'Yes'}
            cancelText={'No'}
          >
            <Button type={'link'} danger data-test={'delete-lead'}>{'Delete'}</Button>
          </Popconfirm>
          <Button
            type={'link'}
            data-test={'edit-lead'}
            onClick={() => {
              setEditLeadId(value.id)
              setEditLeadName(getEditLeadName(value))
              setEditLeadNotes(value.notes)
              setShowEditModal(true)
            }}
          >
            {'Edit'}
          </Button>
        </>
      )
    }
  ]

  const columnsInbound: LeadColumnsType = [
    {
      title: 'Scanned representative',
      key: 'scanned-rep',
      filteredValue: [filterContext.filters?.selectedRepresentative as string].filter(Boolean),
      filters: representativeOptions,
      filterMultiple: false,
      render: (value: LeadData) => {
        if (value.attendee) {
          return `${value.attendee.firstName} ${value.attendee.lastName}`
        }

        if (value.invite) {
          return `${value.invite.firstName} ${value.invite.lastName}`
        }

        return <Tag boxed>{'Deleted user'}</Tag>
      }
    }
  ]

  const columns = scanningDirection === 'outbound' ? [columnsOutbound[0], ...columnsBase, ...columnsOutbound.slice(1)] : [...columnsInbound, ...columnsBase]

  return (
    <Card
      title={'Scans'}
      count={leadsCount}
      testId={'scans'}
      loading={isLoading}
      extra={

        <Button
          className={'export'}
          data-test={'export-data-button'}
          onClick={() => exportCSV(params.eventId, params.sponsorId, scanningDirection)}
          icon={<CloudDownloadOutlined />}
        >
          {`Export ${scanningDirection === 'outbound' ? 'Outbound' : 'Inbound'} CSV`}
        </Button>
      }
    >
      <Actions>
        <Radio.Group value={scanningDirection} onChange={(e) => setScanningDirection(e.target.value)}>
          <Tooltip
            title={'Attendees scanned by your representatives'}
            mouseEnterDelay={1}
            color={'rgba(0,0,0,0.95)'}
            overlayInnerStyle={{ color: '#fff' }}
          >
            <Radio.Button value={'outbound'}>{'Outbound leads'}</Radio.Button>
          </Tooltip>
          <Tooltip
            title={'Attendees who scanned your representatives'}
            mouseEnterDelay={1}
            color={'rgba(0,0,0,0.95)'}
            overlayInnerStyle={{ color: '#fff' }}
          >
            <Radio.Button value={'inbound'}>{'Inbound leads'}</Radio.Button>
          </Tooltip>
        </Radio.Group>
        <Button
          icon={<FilterOutlined />}
          onClick={() => filterContext.setFilterDrawerOpen(true)}
        >
          {'Filters'}
          <Badge count={filterContext.filterCount} style={{ marginLeft: 8, top: -2 }} />
        </Button>
      </Actions>
      <StyledTable
        data-test={'leads-table'}
        dataSource={leads}
        columns={columns}
        rowKey={'id'}
        rowClassName={'scanned-lead-row'}
        style={{ width: '100%' }}
        loading={isLoading}
        bordered
        pagination={{
          current: paginationConfig.page.number,
          pageSize: paginationConfig.page.size,
          total: leadsCount
        }}
        scroll={{ x: 'max-content' }}
        onChange={onTableChange}
      />
      <LeadEditForm
        showEditModal={showEditModal}
        editLeadName={editLeadName}
        editLeadNotes={editLeadNotes}
        onEditCancel={onEditCancel}
        onEditSubmit={onEditSubmit}
      />
      <FilterDrawer
        width={400}
        filterKey={'leads'}
        filterFields={[
          {
            key: 'selectedRepresentative',
            label: 'Representative',
            type: 'select',
            options: representativeOptions,
            inputProps: {
              showSearch: true,
              mode: false,
              filterOption: (input: string, option: { label: string }) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()),
              loading: isLoadingRepresentatives
            }
          }
        ]}
      />
    </Card>
  )
}

const getProspectAttendee = (rowData: LeadData, scanningDirection: ScanningDirection): LeadData['attendee'] | LeadData['invite'] | LeadData['createdBy'] | undefined => {
  // createdBy, attendee, and invite are all attendee data, but with different key

  if (scanningDirection === 'inbound') {
    return rowData.createdBy
  }

  if (rowData.attendee) {
    return rowData.attendee
  }

  if (rowData.invite) {
    if (rowData.invite.firstName && rowData.invite.lastName) {
      return rowData.invite
    }
  }
}

const getEditLeadName = (value: LeadData) => {
  if (value.attendee?.firstName && value.attendee?.lastName) {
    return `${value.attendee.firstName} ${value.attendee.lastName}`
  }

  if (value.attendee?.email) {
    return value.attendee.email
  }

  if (value.invite?.firstName && value.invite?.lastName) {
    return `${value.invite.firstName} ${value.invite.lastName}`
  }

  if (value.invite?.email) {
    return value.invite.email
  }

  return 'Deleted'
}

const exportCSV = async(eventId: number, sponsorId: number, scanningDirection: ScanningDirection) => {
  const url = `${import.meta.env.VITE_API_URL}/admin_panel/events/${eventId}/sponsors/${sponsorId}/leads/export?scanned_by=${scanningDirection === 'inbound' ? 'others' : 'self'}`
  const authHeadersString = window.localStorage.getItem('authHeaders')
  const authHeadersObj = JSON.parse(authHeadersString ?? '{}')

  try {
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        ...authHeadersObj,
        'Cache-Control': 'no-cache',
        ...headerDefinitions.v4.headers
      }
    })

    if (response.ok) {
      message.success('Your CSV export will be delivered by email shortly')
    } else {
      message.error('Error when trying to export CSV')
    }
  } catch (error) {
    console.error(error)
    message.error('Error when trying to export CSV')
  }
}

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    background: ${theme.colors.lightGrayBackground} !important;
  }

  .ant-table-cell a[class="delete-lead"] {
    color: red !important;
  }
` as StyledComponent<TableProps<LeadData>>

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${margins.baseMargin}px;
`

const LeadsWithFilters = withFilterContext(Leads) as React.ComponentType & { routeConfig: { path: string } }

LeadsWithFilters.routeConfig = {
  path: '/scans'
}

export default LeadsWithFilters

import { groupBy, map, path, pipe, pluck, prop, sortBy, toPairs, uniqBy } from 'ramda'
import { Intent, Interest, SelectedInterest } from 'src/Types'

type GroupedInterest = { intent: Intent, interests: Interest[] }

export function groupInterestsByIntent(selectedInterests: SelectedInterest[] = []): GroupedInterest[] {
  const groupedByIntent = groupBy(
    (item) => item.intent.id,
    selectedInterests
  )

  return pipe<
  [Record<string, SelectedInterest[]>],
  [string, SelectedInterest[]][],
  GroupedInterest[],
  GroupedInterest[]
  >(
    toPairs,
    map(([, items]) => ({
      intent: items[0].intent,
      interests: pipe<[SelectedInterest[]], Interest[], Interest[], Interest[]>(
        pluck('interest'),
        uniqBy(prop('id')),
        sortBy(prop('position'))
      )(items)
    })),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    sortBy(path(['intent', 'position']))
  )(groupedByIntent) as GroupedInterest[]
}

import React, { useCallback } from 'react'
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import { ClearOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Field } from '@Reusable/Field/Field'
import { margins } from '@Theme'
import { Button, Drawer, Form } from 'antd'
import { DrawerProps } from 'antd/lib/drawer'

import { useFilters } from './FilterContext'
import FilterDrawerField from './FilterDrawerField'
import { getDefaultValues, transformCachedValueToFieldValue, transformToCachedValues } from './helpers'
import { FilterFieldType } from './Types'

type RenderProps = {
  form: UseFormReturn<Record<string, unknown>>,
  filterFields: FilterFieldType<string>[]
}

interface FilterDrawerProps extends DrawerProps {
  filterKey: string,
  filterFields?: FilterFieldType<string>[],
  onChange?: () => void,
  render?: (renderProps: RenderProps) => React.ReactNode
}

function FilterDrawer({ filterKey, filterFields = [], render, ...rest }: FilterDrawerProps) {
  const { filters, setFilters, isFilterDrawerOpen, setFilterDrawerOpen } = useFilters(filterKey)

  const form = useForm<Record<string, unknown | undefined>>({
    defaultValues: getDefaultValues(filterFields)
  })

  const getFields = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const subFields = filterFields.filter((field) => field.type === 'group').map((field) => field.fields).flat()
    const fields = [...filterFields, ...subFields]
    return fields
  }, [filterFields])

  // Update form values if filters change elsewhere
  React.useEffect(function refillFields() {
    const fields = getFields()

    fields.forEach((filterField) => {
      const value = filters[filterField.key]
      const fieldValue = transformCachedValueToFieldValue(value, filterField)

      form.setValue(filterField.key, fieldValue || null, { shouldTouch: true })
    })
  }, [filters, form, getFields])

  const handleSubmit = form.handleSubmit((values) => {
    setFilters(transformToCachedValues(values, filterFields))
    rest.onChange?.()
    onClose()
  })

  function onClose() {
    setFilterDrawerOpen(false)
  }

  function clear() {
    form.reset()
    handleSubmit()
  }

  const fieldsToWatch = Object.values(getFields()).flatMap((field) => 'watch' in field ? field.watch : []) as string[]
  const watchedValues = form.watch(fieldsToWatch)

  // Remove the value if a field is hidden
  React.useEffect(function resetConditionalFieldValues() {
    getFields().forEach((field) => {
      if ('isHidden' in field && field.isHidden?.(form.getValues())) {
        form.setValue(field.key, undefined)
      }
    })
  }, [watchedValues, form, getFields])

  // Remove the value if a field is disabled
  React.useEffect(function clearDisabledFields() {
    getFields().forEach((field) => {
      if ('isDisabled' in field && field.isDisabled?.(form.getValues())) {
        form.setValue(field.key, undefined)
      }
    })
  }, [watchedValues, form, getFields])

  return (
    <FormProvider {...form}>
      <Drawer
        title={'Filters'}
        visible={isFilterDrawerOpen}
        onClose={onClose}
        {...rest}
        footer={(
          <Actions>
            <Button
              type={'primary'}
              onClick={handleSubmit}
            >
              {'Apply'}
            </Button>
            <Button onClick={() => onClose()}>{'Cancel'}</Button>
            <Button
              danger
              style={{ marginRight: 'auto' }}
              onClick={clear}
              icon={<ClearOutlined />}
            >
              {'Clear filters'}
            </Button>
          </Actions>
        )}
      >
        <StyledForm layout={'vertical'}>
          {render
            ? render({ filterFields, form })
            : filterFields.map((filter) => {
              if ('isHidden' in filter && filter.isHidden?.(form.getValues())) return null

              if (filter.type === 'group') {
                return (
                  <Group key={filter.key}>
                    {filter.fields.map((filter) => {
                      if ('isHidden' in filter && filter.isHidden?.(form.getValues())) return <EmptyCell />

                      return (
                        <Field
                          label={filter.label}
                          name={filter.key}
                          key={filter.key}
                          render={({ field }) => (
                            <FilterDrawerField
                              {...field}
                              filter={filter}
                            />
                          )}
                        />
                      )
                    })}
                  </Group>
                )
              }

              return (
                <Field
                  label={filter.label}
                  name={filter.key}
                  key={filter.key}
                  render={({ field }) => (
                    <FilterDrawerField
                      {...field}
                      filter={filter}
                    />
                  )}
                />
              )
            })}
        </StyledForm>
      </Drawer>
    </FormProvider>
  )
}

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: ${margins.halfBaseMargin}px;
`

const Actions = styled.div`
  display: flex;
  gap: ${margins.halfBaseMargin}px;
  flex-direction: row-reverse;
`

const Group = styled.div`
  display: flex;
  gap: ${margins.baseMargin}px;
};

  > .ant-row {
    flex: 1;
    flex-direction: row;
  }
`

const EmptyCell = styled.div`
  flex: 1;
`

export default FilterDrawer

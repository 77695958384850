import React from 'react'
import { Event } from '@Hooks/Types'
import useSelectedEvent from '@Hooks/useSelectedEvent'

import { Cache } from './helpers'
import { FilterContextType, FilterStateType, FiltersType } from './Types'

export const FilterContext = React.createContext({} as FilterContextType)

/**
 * @example
 * type FilterValues = {
 *   status: string[],
 *   type: string,
 *   advancedType: string,
 *   customField: string
 * }
 *
 * const filterFields: FilterFieldType[] = [
 *   {
 *     label: 'Status',
 *     key: 'status',
 *     type: 'checkbox',
 *     options: [
 *       { label: 'Active', value: 'active' },
 *       { label: 'Inactive', value: 'inactive' }
 *     ]
 *   },
 *   {
 *     label: 'Type',
 *     key: 'type',
 *     type: 'select',
 *     inputProps: {
 *       mode: false,
 *       style: { border: '1px solid skyblue', padding: '1rem' }
 *     },
 *     options: [
 *       { label: 'Basic', value: 'basic' },
 *       { label: 'Advanced', value: 'advanced' }
 *     ]
 *   },
 *   {
 *     label: 'Advanced Type',
 *     key: 'advancedType',
 *     type: 'select',
 *     options: [
 *       { label: 'Advanced 1', value: 'ac' },
 *       { label: 'Advanced 2', value: 'advanced' }
 *     ],
 *
 *     // Conditional field:
 *     // This field will only be shown if the 'type' field is set to 'advanced'
 *     watch: ['type'],
 *     isHidden: (filters) => filters.type !== 'advanced'
 *   },
 *   {
 *     label: 'Custom Field',
 *     key: 'customField',
 *     type: 'custom',
 *     render: ({ value, onChange }) => (
 *       <input
 *         type={'text'}
 *         value={value}
 *         onChange={(e) => onChange(e.target.value)}
 *       />
 *     )
 *   },
 *   {
 *     key: 'Date group',
 *     type: 'group',
 *     fields: [
 *       {
 *         key: 'date',
 *         label: 'Date',
 *         type: 'custom',
 *         cachedValueToFieldValue: (value) => value ? moment(value) : null,
 *         render: (field) => <DatePicker {...field} />
 *       },
 *       {
 *         label: 'Time range',
 *         key: 'timeRange',
 *         type: 'custom',
 *         cachedValueToFieldValue: (value) => {
 *           return value ? [moment(value?.[0]), moment(value?.[1])] : undefined
 *         },
 *         render: (field) => <TimePicker.RangePicker {...field} />
 *       }
 *     ]
 *   },
 * ]
 *
 * return (
 *   <FilterProvider scope={event.id}>
 *     <FilterConsumer<FilterValues> filterKey={'myFilters'}>
 *       {({ filterCount, setFilterDrawerOpen }) => (
 *         <button onClick={() => setFilterDrawerOpen(true)}>
 *           {'Filter table '}
 *           <Badge count={filterCount} />
 *         </button>
 *       )}
 *     </FilterConsumer>
 *     <FilterDrawer
 *       filterKey={'myFilters'}
 *       filterFields={filterFields}
 *       width={500}
 *     />
 *   </FilterProvider>
 * )
 *
 * @example
 * // Use the hook
 * const { filters, addFilters, setFilters, clearFilters, isFilterDrawerOpen, setFilterDrawerOpen, filterCount } = useFilters<FilterValues>('myFilters')
 *
 * @example
 * // Use Filter Context HOC
 * export default withFilterContext(MyComponent, { scope: ({ event }) => event.id })
 */
export default function FilterProvider({ children, scope }: { children: React.ReactNode, scope?: string }) {
  const [openDrawers, setOpenDrawers] = React.useState<string[]>([])
  const [filterState, setFilterState] = React.useState(Cache.getFilters)

  React.useEffect(function cacheFiltersOnChange() {
    if (!filterState || Object.keys(filterState).length === 0) {
      Cache.clear()
    } else {
      Cache.setFilters(filterState)
    }
  }, [filterState])

  function setFilters(filterKey: string, newFilters: FiltersType) {
    setFilterState({
      ...filterState,
      [filterKey]: newFilters
    })
  }

  function addFilters(filterKey: string, newFilters: FiltersType) {
    const updatedFilters = {
      ...(filterState?.[filterKey] || {}),
      ...newFilters
    }

    setFilterState({
      ...filterState,
      [filterKey]: updatedFilters
    })
  }

  function clearFilters(filterKey: string) {
    setFilterState({
      ...filterState,
      [filterKey]: undefined
    })
  }

  function removeFilters(filterKey: string, keys: string | string[]) {
    keys = Array.isArray(keys) ? keys : [keys]
    const filters = keys.reduce((acc, key) => ({ ...acc, [key]: undefined }), {})

    addFilters(filterKey, filters)
  }

  return (
    <FilterContext.Provider value={{ filterState, setFilters, addFilters, removeFilters, clearFilters, openDrawers, setOpenDrawers, scope }}>
      {children}
    </FilterContext.Provider>
  )
}

export function useFilters<T extends FilterStateType>(filterKey: keyof T & string) {
  const { filterState, setFilters, addFilters, removeFilters, clearFilters, openDrawers = [], setOpenDrawers, scope } = React.useContext<FilterContextType<T>>(FilterContext)
  const scopedFilterKey = `${scope ? `${scope}_` : ''}${filterKey}`

  const filters = React.useMemo(
    () => filterState?.[scopedFilterKey] || {},
    [filterState, scopedFilterKey]
  )
  const filterCount = Object.values(filters).filter((value) => value !== undefined).length
  const isFilterDrawerOpen = openDrawers.includes(scopedFilterKey)

  function setFilterDrawerOpen(shouldOpen: boolean) {
    if (shouldOpen) {
      setOpenDrawers([...openDrawers, scopedFilterKey])
    } else {
      setOpenDrawers(openDrawers.filter((key) => key !== scopedFilterKey))
    }
  }

  return {
    filters,
    filterCount,
    setFilters: (filters: FiltersType) => setFilters(scopedFilterKey, filters),
    addFilters: (filters: FiltersType) => addFilters(scopedFilterKey, filters),
    removeFilters: (keys: string | string[]) => removeFilters(scopedFilterKey, keys),
    clearFilters: () => clearFilters(scopedFilterKey),
    isFilterDrawerOpen,
    setFilterDrawerOpen
  }
}

export function FilterConsumer<T extends FilterStateType>({ filterKey, children }: {
  filterKey: keyof T & string,
  children: (params: ReturnType<typeof useFilters<T>>) => React.ReactElement
}): React.ReactElement {
  return children(useFilters<T>(filterKey))
}

export function withFilterContext(Component: React.ComponentType, { scope }: { scope?: ({ event }: { event: Event }) => string } = {}): React.ComponentType {
  return function ThemeComponent(props: React.ComponentProps<typeof Component>) {
    const { selectedEvent: event } = useSelectedEvent() as { selectedEvent: Event }

    return (
      <FilterProvider scope={scope?.({ event })}>
        <Component {...props} />
      </FilterProvider>
    )
  }
}

import { InfoCircleOutlined } from '@ant-design/icons'
import useSponsorStatistics, { MeetingsSummary } from '@Hooks/useSponsorStatistics/useSponsorStatistics'
import useVisitorsStatistics from '@Hooks/useVisitorsStatistics'
import { StyledFlex, StyledLightInnerTooltip, StyledSubtitleText } from '@Reusable/Styled'
import theme from '@Theme'
import { Col, Row, Tooltip } from 'antd'
import { find, propEq } from 'ramda'

import ActiveVisitors from './ActiveVisitors/ActiveVisitors'
import BoothVisitsActivity from './BoothVisitsActivity/BoothVisitsActivity'
import ChatActivity from './ChatActivity/ChatActivity'
import MeetingRequestActivity from './MeetingRequestActivity/MeetingRequestActivity'
import OverAllPerformance from './OverAllPerformance/OverAllPerformance'
import { secondsToHms } from './Reusable/Common'
import NumericStatCard, { NumericStat } from './Reusable/NumericStatCard'

const mainStatsCardLayout = {
  xl: 6,
  lg: 12,
  md: 12,
  sm: 24,
  xs: 24
}

const chartStatsCardLayout = {
  xl: 12,
  lg: 24,
  md: 24,
  sm: 24,
  xs: 24
}

const ACCEPTANCE_AVERAGE_LOW = 28
const ACCEPTANCE_AVERAGE_HIGH = 35
const OUTBOUND_MEETINGS_LOW = 6
const OUTBOUND_MEETINGS_HIGH = 9

const StatCards = () => {
  const { meetingSummary, isFetching: isFetchingSponsorStatistics } = useSponsorStatistics()
  const { visitorsStatistics } = useVisitorsStatistics()

  const {
    totalTimeSpent,
    totalUniqueVisitors
  } = visitorsStatistics

  const outboundMeetingRequests = find(propEq('key', 'Total meetings'), meetingSummary as MeetingsSummary[])?.outbound ?? 0
  const acceptedMeetingRequests = find(propEq('key', 'Meetings: Accepted'), meetingSummary as MeetingsSummary[])?.total ?? 0
  const totalMeetings = find(propEq('key', 'Total meetings'), meetingSummary as MeetingsSummary[])?.total ?? 0
  const meetingAcceptanceRate = totalMeetings ? (acceptedMeetingRequests * 100 / totalMeetings) : 0

  const renderOutboundMeetingRecommend = (value: number) => {
    let color = theme.colors.dangerRed
    let label = 'Below recommended'
    let toolTipText = (
      <>
        {'We recommend sending out at least'}
        {' '}
        <b>{'10'}</b>
        {' '}
        {'meeting requests to have a successful networking experience.'}
      </>
    )
    if (value >= OUTBOUND_MEETINGS_LOW && value <= OUTBOUND_MEETINGS_HIGH) {
      color = theme.colors.infoOrange
    } else if (value > OUTBOUND_MEETINGS_HIGH) {
      color = theme.colors.primary
      label = 'Above recommended'
      toolTipText = <>{'Fantastic job! Keep on reaching out to people!'}</>
    }
    return (
      <StyledFlex data-test={'outbound-meeting-recommended'}>
        <StyledSubtitleText fontSize={'16px'} margin={'12px 6px 12px 16px'}>
          <span style={{ fontWeight: 600, color }}>{label}</span>
        </StyledSubtitleText>
        <Tooltip
          color={theme.colors.primaryBackground} title={<StyledLightInnerTooltip>
            {toolTipText}
          </StyledLightInnerTooltip>}
        >
          <InfoCircleOutlined style={{ cursor: 'pointer', marginTop: 2 }}/>
        </Tooltip>
      </StyledFlex>
    )
  }

  const renderAcceptanceRateAverage = (value: number) => {
    let color = theme.colors.infoOrange
    let label = 'Below average'
    if (value >= ACCEPTANCE_AVERAGE_LOW && value <= ACCEPTANCE_AVERAGE_HIGH) {
      color = theme.colors.primary
      label = 'Average'
    } else if (value > ACCEPTANCE_AVERAGE_HIGH) {
      color = theme.colors.primary
      label = 'Above average'
    }
    return (
      <StyledFlex data-test={'meeting-acceptance-average'}>
        <StyledSubtitleText fontSize={'16px'} margin={'12px 6px 12px 16px'} style={{ cursor: 'pointer' }}>
          <span style={{ fontWeight: 600, color }}>{label}</span>
        </StyledSubtitleText>
        <Tooltip
          color={theme.colors.primaryBackground} title={<StyledLightInnerTooltip>
            {'Events in Brella have an average acceptance rate of'}
            {' '}
            <b>{'28'}</b>
            {' '}
            {'to'}
            {' '}
            <b>{'35%'}</b>
            {'.'}
          </StyledLightInnerTooltip>}
        >
          <InfoCircleOutlined style={{ cursor: 'pointer', marginTop: 2 }}/>
        </Tooltip>
      </StyledFlex>
    )
  }

  const mainStats = [
    {
      key: 1,
      label: 'Total unique visitors',
      value: totalUniqueVisitors
    },
    {
      key: 2,
      label: 'Outbound meeting requests',
      value: outboundMeetingRequests,
      extra: renderOutboundMeetingRecommend(outboundMeetingRequests),
      loading: isFetchingSponsorStatistics
    },
    {
      key: 3,
      label: 'Meeting acceptance rate',
      labelToolTip: 'The data is from one week before the event starts to one week after the event ends.',
      value: meetingAcceptanceRate.toFixed(),
      suffix: '%',
      extra: renderAcceptanceRateAverage(meetingAcceptanceRate),
      loading: isFetchingSponsorStatistics
    },
    {
      key: 4,
      label: 'Total time spent in booth',
      value: secondsToHms(totalTimeSpent) || '0s'
    }
  ]

  return (
    <Row gutter={[24, 24]}>
      {mainStats.map((stat: NumericStat) => {
        return (
          <Col
            key={stat.key}
            className={'gutter-row'}
            {...mainStatsCardLayout}
          >
            <NumericStatCard stat={stat} />
          </Col>
        )
      })}
      <Col xl={24}>
        <ActiveVisitors/>
      </Col>
      <Col {...chartStatsCardLayout}>
        <MeetingRequestActivity />
      </Col>
      <Col {...chartStatsCardLayout}>
        <ChatActivity />
      </Col>
      <Col {...chartStatsCardLayout}>
        <OverAllPerformance />
      </Col>
      <Col {...chartStatsCardLayout}>
        <BoothVisitsActivity />
      </Col>
    </Row>
  )
}

export default StatCards
